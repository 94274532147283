@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "ClashDisplay";
  src: url("/src/font/ClashDisplay-Bold.otf") format("opentype");
  font-weight: 700;
}
@font-face {
  font-family: "ClashDisplay";
  src: url("/src/font/ClashDisplay-Extralight.otf") format("opentype");
  font-weight: 200;
}
@font-face {
  font-family: "ClashDisplay";
  src: url("/src/font/ClashDisplay-Light.otf") format("opentype");
  font-weight: 300;
}
@font-face {
  font-family: "ClashDisplay";
  src: url("/src/font/ClashDisplay-Medium.otf") format("opentype");
  font-weight: 500;
}
@font-face {
  font-family: "ClashDisplay";
  src: url("/src/font/ClashDisplay-Regular.otf") format("opentype");
  font-weight: 400;
}
@font-face {
  font-family: "ClashDisplay";
  src: url("/src/font/ClashDisplay-Semibold.otf") format("opentype");
  font-weight: 600;
}
@font-face {
  font-family: "Satoshi";
  src: url("/src/font/Satoshi-Bold.otf") format("opentype");
  font-weight: 700;
}
@font-face {
  font-family: "Satoshi";
  src: url("/src/font/Satoshi-Italic.otf") format("opentype");
  font-weight: 200;
}
@font-face {
  font-family: "Satoshi";
  src: url("/src/font/Satoshi-Light.otf") format("opentype");
  font-weight: 300;
}
@font-face {
  font-family: "Satoshi";
  src: url("/src/font/Satoshi-Medium.otf") format("opentype");
  font-weight: 500;
}
@font-face {
  font-family: "Satoshi";
  src: url("/src/font/Satoshi-Regular.otf") format("opentype");
  font-weight: 400;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.text-label {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
}

.home-hero {
  background-image: url("../public/assets/home/home.svg");
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
.user-section {
  background-image: url("../public/assets/home/bg-1.webp");
  height: 823px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.custom-range {
  max-width: 425px;
  width: 100%;
  background: #26242b;
}
.custom-range::-webkit-slider-thumb {
  background: #fd243e;
}
.custom-range::-moz-range-thumb {
  background: #fd243e;
  cursor: pointer;
}

@media (max-width: 768px) {
  .user-section {
    height: auto;
    background-image: url("../public/assets/photo1.png");
  }
}

.text-line-s {
  text-shadow: 0 1px 0px #000000, 1px 0 0px #000000, 1px 2px 1px #000000,
    2px 1px 1px #000000, 2px 3px 2px #000000, 3px 2px 2px #000000,
    3px 4px 2px #000000, 4px 3px 3px #000000, 4px 5px 3px #000000,
    5px 4px 2px #000000, 5px 6px 2px #000000, 6px 5px 2px #000000,
    6px 7px 1px #000000, 7px 6px 1px #000000, 7px 8px 0px #000000,
    8px 7px 0px #000000;
}

.blur-footer {
  backdrop-filter: blur(8px);
}

.cursor-none {
  cursor: none;
}

.cursor-none * {
  cursor: none;
}
@media (max-width: 1300px) {
  .homepage-cards-container {
    max-width: 1280px;
  }
}

@media (max-height: 777px) {
  .homepage-ignite-text {
    height: 195px;
  }
  .homepage-cards-container {
    max-width: 1080px;
  }
}
.homepage-wrapper {
  background-image: url("../public/assets/home/home.svg");
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Add this new class for the button animation */
.hover-fill-animation {
  position: relative;
  isolation: isolate;
  overflow: hidden;
}

.hover-fill-animation::before {
  content: "";
  position: absolute;
  left: -100%;
  top: 0;
  width: 100%;
  height: 100%;
  background: white;
  transition: left 0.3s ease;
  z-index: -1;
}

.hover-fill-animation:hover::before {
  left: 0;
}

.hover-fill-animation img {
  transition: filter 0.3s ease;
}

.hover-fill-animation:hover img {
  filter: brightness(0); /* This will make the white arrow image black */
}
